import React, { Component } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Popconfirm,
  Select,
  message,
  Checkbox,
  Col,
  Switch,
} from "antd";
import { connect } from "react-redux";
import {
  getAllUsers,
  getAllGroups,
  registerUser,
  modifyUser,
  deleteUser,
  addGroupToUser,
  removeGroupFromUser,
  activateUser,
} from "../../actions/users";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  PlusCircleFilled,
  SaveFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { getAllCompanies } from "../../actions/company";
import { withTranslation } from "react-i18next";
import { getAllOffices } from "../../actions/office";
import { userRoles } from "../../constants/roles-types";

const { Option } = Select;

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (groupId, companyId) =>
      dispatch(getAllUsers(groupId, companyId)),
    getAllGroups: () => dispatch(getAllGroups()),
    activateUser: (userId) => dispatch(activateUser(userId)),
    registerUser: (user, token) => dispatch(registerUser(user, token)),
    modifyUser: (user, token) => dispatch(modifyUser(user, token)),
    deleteUser: (userId) => dispatch(deleteUser(userId)),
    getAllCompanies: () => dispatch(getAllCompanies()),
    getAllOffices: () => dispatch(getAllOffices()),
    addGroupToUser: (userId, groupId, callback) =>
      dispatch(addGroupToUser(userId, groupId, callback)),
    removeGroupFromUser: (userId, groupId, callback) =>
      dispatch(removeGroupFromUser(userId, groupId, callback)),
  };
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedUsers extends Component {
  formRef = React.createRef();

  state = {
    users: [],
    loaded: false,
    visible: false,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    position: "",
    phoneNumber: "",
    firstVehicle: null,
    secondVehicle: null,
    group: 1,
    selectedUser: {},
    modify: false,
    office: null,
    company: [],
    reception: [],
    hradmin: [],
  };

  componentDidMount = async () => {
    await this.props.getAllGroups();
    if (!this.props.groupsToLoad.includes(userRoles.SYSTEM_ADMIN)) {
      await this.props.getAllCompanies();
    }
    if (this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN)) {
      await this.props.getAllOffices();
    }
    await this.getUsers();
    await this.getAllReception();
    await this.getAllHRAdmin();
    this.setState({ loaded: true });
  };

  setDefaultValues = () => {
    this.setState({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      position: "",
      group: 1,
      company: [],
      office: null,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeGroup = (event) => {
    this.setState({ group: event });
  };

  getUsers = async () => {
    this.setState({ loaded: false });
    await this.props.getAllUsers(this.props.groupsToLoad);
    if (this.props.usersStatus) {
      if (
        !this.props.groupsToLoad.includes(userRoles.SYSTEM_ADMIN) &&
        !this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN)
      ) {
        const users = this.props.users.filter(
          (user) => user.companyId !== null
        );
        this.setState({ users: users });
      } else {
        this.setState({ users: this.props.users });
      }
    }
    this.setState({ loaded: true });
  };

  handleClickNewUser = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false, modify: false });
    this.formRef.current.resetFields();
  };

  handleSave = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      await this.submitForm();
    }
    await this.getUsers();
  };

  handleActivateUser = async (record) => {
    await this.props.activateUser(record.id);
    await this.getUsers();
  };

  submitForm = async () => {
    const user = {
      id: this.state.selectedUser.id,
      email: this.state.email,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      position: this.state.position,
      groupId: [],
      phoneNumber: this.state.phoneNumber,
      companyId: this.state.company
        ? this.state.company
        : this.props.user.companyId,
      officeId: this.state.office,
      active: true,
      firstVehicle: this.state.firstVehicle,
      secondVehicle: this.state.secondVehicle,
    };

    if (this.state.modify) {
      user.groupId = this.state.selectedUser.groups.map((group) => {
        return group.id;
      });
      await this.props.modifyUser(user, this.props.token);
    } else {
      if (this.props.groupsToLoad.includes(userRoles.SYSTEM_ADMIN)) {
        user.groupId = [userRoles.SYSTEM_ADMIN];
      } else if (this.props.groupsToLoad.includes(userRoles.COMPANY_ADMIN)) {
        user.groupId = [userRoles.COMPANY_ADMIN];
      } else if (this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN)) {
        user.officeId = this.state.office || this.props.user.officeId;
        user.groupId = [userRoles.OFFICE_ADMIN];
        user.companyId = null;
      }
      user.groupId.push(userRoles.USER);
      await this.props.registerUser(user, this.props.token);
    }
    if (this.props.saveStatus) {
      this.setDefaultValues();
      this.formRef.current.resetFields();
      this.setState({ visible: false, modify: false });
    } else {
      if (this.props.message.code === 1003) {
        message.error(
          this.props.t("save-fail-because-email-already-exists"),
          5
        );
      }
    }
  };

  getAllHRAdmin = async () => {
    await this.props.getAllUsers(
      [userRoles.HR_ADMIN],
      this.props.user.companyId
    );
    if (this.props.usersStatus) {
      const hrKeys = this.props.users.map((user) => {
        return String(user.id);
      });
      this.setState({ hradmin: hrKeys });
    }
  };

  getAllReception = async () => {
    await this.props.getAllUsers(
      [userRoles.RECEPTION_ADMIN],
      this.props.user.companyId
    );
    if (this.props.usersStatus) {
      const receptionKeys = this.props.users.map((user) => {
        return String(user.id);
      });
      this.setState({ reception: receptionKeys });
    }
  };

  handleChangeReception = async (event) => {
    let reception = this.state.reception;
    reception = [...reception, event];
    const changeState = () => {
      this.setState({ reception: reception });
    };
    if (
      (this.props.user.company.subscription.maxReception === null
        ? Number.MAX_SAFE_INTEGER
        : this.props.user.company.subscription.maxReception) >=
      this.state.reception.length + 1
    ) {
      await this.props.addGroupToUser(
        event,
        userRoles.RECEPTION_ADMIN,
        changeState
      );
    } else {
      message.error(this.props.t("companyadmin-reception-max-error"), 5);
    }
  };

  handleDeselectReception = async (event) => {
    const reception = this.state.reception.filter((id) => id !== event);

    const changeState = () => {
      this.setState({ reception: reception });
    };
    await this.props.removeGroupFromUser(
      event,
      userRoles.RECEPTION_ADMIN,
      changeState
    );
  };

  handleChangeHr = async (event) => {
    let hr = this.state.hradmin;
    hr = [...hr, event];

    const changeState = () => {
      this.setState({ hradmin: hr });
    };
    if (
      (this.props.user.company.subscription.maxHRadmin === null
        ? Number.MAX_SAFE_INTEGER
        : this.props.user.company.subscription.maxHRadmin) >=
      this.state.hradmin.length + 1
    ) {
      await this.props.addGroupToUser(event, userRoles.HR_ADMIN, changeState);
    } else {
      message.error(this.props.t("companyadmin-hr-max-error"), 5);
    }
  };

  handleDeselectHr = async (event) => {
    const hr = this.state.hradmin.filter((id) => id !== event);
    const changeState = () => {
      this.setState({ hradmin: hr });
    };

    await this.props.removeGroupFromUser(
      event,
      userRoles.HR_ADMIN,
      changeState
    );
  };

  handleChangeHrCheckbox = async (event) => {
    if (event.target.checked === true) {
      await this.handleChangeHr(this.state.selectedUser.id);
    } else {
      await this.handleDeselectHr(this.state.selectedUser.id);
    }
    await this.getAllHRAdmin();
  };

  handleChangeReceptionCheckbox = async (event) => {
    if (event.target.checked === true) {
      await this.handleChangeReception(this.state.selectedUser.id);
    } else {
      await this.handleDeselectReception(this.state.selectedUser.id);
    }
    await this.getAllReception();
  };

  handleModifyUser = (user) => {
    let vehicles = [];

    if (user.vehicles) {
      user.vehicles.forEach((vehicle) => {
        vehicles.push(vehicle.licencePlateNumber);
      });
    }
    this.setState(
      {
        selectedUser: user,
        modify: true,
        email: user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        position: user.position,
        phoneNumber: user.phoneNumber,
        company: user.companyId,
        office: user.officeId,
        firstVehicle: vehicles.length > 0 ? vehicles[0] : null,
        secondVehicle: vehicles.length > 1 ? vehicles[1] : null,
      },
      async () => {
        await this.handleClickNewUser();
        this.formRef.current.setFieldsValue({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
          position: user.position,
          phoneNumber: user.phoneNumber,
          company: user.companyId,
          office: user.officeId,
          firstVehicle: vehicles.length > 0 ? vehicles[0] : null,
          secondVehicle: vehicles.length > 1 ? vehicles[1] : null,
        });
      }
    );
  };

  handleChangeCompany = (event) => {
    this.setState({ company: event });
  };

  handleChangeOffice = (event) => {
    this.setState({ office: event });
    let copmanyFilter = this.props.companies.filter(
      (company) => company.officeId === event
    );
    this.setState({ company: copmanyFilter });
  };

  handleDeleteUser = async (user) => {
    await this.props.deleteUser(user.id);
    await this.getUsers();
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] || "")
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  render() {
    const columns = [
      {
        title: this.props.t("email-label"),
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email", this.props.t("email-label")),
      },
      {
        title: this.props.t("guest-register-lastname-label"),
        dataIndex: "lastName",
        key: "lastName",
        ...this.getColumnSearchProps(
          "lastName",
          this.props.t("guest-register-lastname-label")
        ),
      },
      {
        title: this.props.t("guest-register-firstname-label"),
        dataIndex: "firstName",
        key: "firstName",
        ...this.getColumnSearchProps(
          "firstName",
          this.props.t("guest-register-firstname-label")
        ),
      },
      {
        title: this.props.t("reception-events-table-header-date"),
        key: "date",
        render: (text, record) => (
          <Space size="middle">
            {new Date(record.created * 1000).toLocaleDateString()}
          </Space>
        ),
      },
      !this.props.groupsToLoad.includes(userRoles.SYSTEM_ADMIN) &&
      !this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN)
        ? {
            title: this.props.t("companies-label-companyname"),
            key: "company",
            render: (text, record) => {
              return (
                <Space size="middle">
                  {
                    this.props.companies
                      .find((company) => company.id === record.companyId)
                      ?.companyTexts.find(
                        (text) =>
                          text.languageId ===
                            this.props.companies.find(
                              (company) => company.id === record.companyId
                            ).defaultLanguageId && text.textId === 3
                      )?.textValue
                  }
                </Space>
              );
            },
          }
        : {},
      {
        title: this.props.t("modify-button-label"),
        key: "modify",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleModifyUser(record)}>
              {this.props.t("modify-button-label")}
            </Button>
          </Space>
        ),
      },
      {
        title: this.props.t("delete-button-label"),
        key: "delete",
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              disabled={record.id === this.props.user.id}
              title={this.props.t("workers-worker-delete-confirm")}
              okText={this.props.t("yes-button-label")}
              cancelText={this.props.t("no-button-label")}
              onConfirm={() => this.handleDeleteUser(record)}
            >
              <Button danger disabled={record.id === this.props.user.id}>
                {this.props.t("delete-button-label")}
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    if (this.props.user.groups.some((group) => group.id === 1)) {
      columns.push({
        title: this.props.t("activate-button-label"),
        key: "activate",
        render: (text, record) => {
          return (
            <Space size="middle">
              <Switch
                checked={record.active}
                onChange={() => this.handleActivateUser(record)}
              />
            </Space>
          );
        },
      });
    }
    return (
      <div>
        <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
          <strong>{this.props.t("menu-users-button-label")}</strong>
        </h1>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ float: "right" }}>
            <Button
              type="primary"
              onClick={this.handleClickNewUser}
              style={{ margin: "0.5em" }}
            >
              <PlusCircleFilled />
              {this.props.t("users-new-user-button-label")}
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={this.state.users}
          locale={{ emptyText: this.props.t("empty-text") }}
          loading={!this.props.usersStatus && !this.state.loaded}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
          }}
        />

        <Modal
          maskClosable={false}
          visible={this.state.visible}
          title={this.props.t("users-new-user-button-label")}
          onOk={this.handleSave}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              <CloseCircleFilled />
              {this.props.t("cancel-button-label")}
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleSave}>
              <SaveFilled />
              {this.state.modify
                ? this.props.t("modify-button-label")
                : this.props.t("register-button-label")}
            </Button>,
          ]}
        >
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="register"
            onFinish={this.submitForm}
            scrollToFirstError
          >
            <Form.Item
              name="lastName"
              label={this.props.t("guest-register-lastname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("guest-register-lastname-text"),
                },
              ]}
            >
              <Input
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="firstName"
              label={this.props.t("guest-register-firstname-label")}
              rules={[
                {
                  required: true,
                  message: this.props.t("guest-register-firstname-text"),
                },
              ]}
            >
              <Input
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={this.props.t("email-label")}
              rules={[
                {
                  type: "email",
                  message: this.props.t("email-wrong-format"),
                },
                {
                  required: true,
                  message: this.props.t("email-empty"),
                },
              ]}
            >
              <Input
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Form.Item>
            {!this.props.groupsToLoad.includes(userRoles.SYSTEM_ADMIN) &&
              !this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN) &&
              this.state.modify && (
                <Form.Item
                  name="password"
                  label={this.props.t("password-label")}
                  rules={[
                    {
                      required: this.state.modify ? false : true,
                      message: this.props.t("password-empty"),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                </Form.Item>
              )}
            {!this.props.groupsToLoad.includes(userRoles.SYSTEM_ADMIN) &&
              !this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN) && (
                <Form.Item
                  name="position"
                  label={this.props.t("profile-position-label")}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("profile-position-text"),
                    },
                  ]}
                >
                  <Input
                    name="position"
                    value={this.state.position}
                    onChange={this.handleChange}
                  />
                </Form.Item>
              )}
            {!this.props.groupsToLoad.includes(userRoles.SYSTEM_ADMIN) &&
              !this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN) && (
                <Form.Item
                  name="phoneNumber"
                  label={this.props.t("profile-phone-label")}
                  rules={[
                    {
                      required: false,
                      message: this.props.t("profile-phone-text"),
                    },
                    {
                      pattern:
                        // eslint-disable-next-line no-useless-escape
                        /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
                      message: this.props.t("wrong-format"),
                    },
                    {
                      max: 15,
                      message: this.props.t("phone-number-max-digit"),
                    },
                  ]}
                >
                  <Input
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange}
                  />
                </Form.Item>
              )}
            {this.props.user.groups.some((group) => group.id === 1) &&
              this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN) && (
                <Form.Item
                  name="office"
                  label={this.props.t("office")}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("messages-office-text"),
                    },
                  ]}
                >
                  <Select
                    name="office"
                    value={this.state.office}
                    onChange={this.handleChangeOffice}
                  >
                    {this.props.offices.map((office) => {
                      return (
                        <Option key={office.id} value={office.id}>
                          {office.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

            {this.hasRight([userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN]) &&
              this.props.groupsToLoad.includes(userRoles.USER) &&
              // this.props.groupsToLoad.includes(
              //   userRoles.SYSTEM_ADMIN ||
              //     (this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN)
              this.state.company !== null && (
                <Form.Item
                  name="company"
                  label={this.props.t(
                    "companyadmin-languages-companyname-label"
                  )}
                  rules={[
                    {
                      required: false,
                      message: this.props.t("messages-company-text"),
                    },
                  ]}
                >
                  <Select
                    name="company"
                    value={this.state.company}
                    onChange={this.handleChangeCompany}
                  >
                    {this.state.company !== null
                      ? this.props.companies?.map((company) => {
                          return (
                            <Option key={company.id} value={company.id}>
                              {company.name}
                            </Option>
                          );
                        })
                      : []}
                  </Select>
                </Form.Item>
              )}
            {!this.props.groupsToLoad.includes(userRoles.SYSTEM_ADMIN) &&
              !this.props.groupsToLoad.includes(userRoles.OFFICE_ADMIN) &&
              this.state.modify === true && (
                <Col>
                  <Form.Item name="hrcheckbox" label={this.props.t()}>
                    <div style={{ position: "relative", left: "10em" }}>
                      <Space size="middle">
                        {this.props.t("workers-group-hr")}
                        <Checkbox
                          checked={this.state.hradmin.includes(
                            String(this.state.selectedUser.id)
                          )}
                          onChange={(event) =>
                            this.handleChangeHrCheckbox(event)
                          }
                        />
                        {this.props.t("workers-group-reception")}
                        <Checkbox
                          checked={this.state.reception.includes(
                            String(this.state.selectedUser.id)
                          )}
                          onChange={(event) =>
                            this.handleChangeReceptionCheckbox(event)
                          }
                        />
                      </Space>
                    </div>
                  </Form.Item>
                </Col>
              )}

            <Form.Item
              name="firstVehicle"
              label={this.props.t("profile-vehicle-plate-number-n1")}
            >
              <Input
                name="firstVehicle"
                value={this.state.firstVehicle}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="secondVehicle"
              label={this.props.t("profile-vehicle-plate-number-n2")}
            >
              <Input
                name="secondVehicle"
                value={this.state.secondVehicle}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Button hidden htmlType="submit"></Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  users: state.usersReducer.users,
  groups: state.usersReducer.groups,
  usersStatus: state.usersReducer.status,
  saveStatus: state.usersReducer.saveStatus,
  companies: state.companyReducer.companies,
  offices: state.officeReducer.offices,
  message: state.usersReducer.message,
});

const Users = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers)
);
export default Users;
