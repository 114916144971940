import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Input,
  Popconfirm,
  Modal,
  Form,
  message,
} from "antd";
import Highlighter from "react-highlight-words";
import { DeleteFilled, EditFilled, SearchOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import {
  deleteVehicle,
  getVehiclesList,
  updateVehicle,
} from "../../actions/parking";
import { modifyUser } from "../../actions/users";
import { modifyGuest, modifyPostCreatedGuest } from "../../actions/reception";
import { getAllCompanies } from "../../actions/company";
import { userRoles } from "../../constants/roles-types";
import lodash from "lodash";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllVehicle: () => dispatch(getVehiclesList()),
    deleteVehicle: (id) => dispatch(deleteVehicle(id)),
    updateVehicle: (id, params) => dispatch(updateVehicle(id, params)),
    modifyUser: (user) => dispatch(modifyUser(user)),
    modifyGuest: (params) => dispatch(modifyGuest(params)),
    modifyPostCreatedGuest: (params) =>
      dispatch(modifyPostCreatedGuest(params)),
    getAllCompanies: () => dispatch(getAllCompanies()),
  };
};
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
class ConnectedVehicles extends Component {
  state = {
    loaded: false,
    parking: [],
    parkingGroups: [],
    users: [],
    vehicles: [],
    filteredInfo: {},
    detailsModalVisible: false,
    selectedUser: [],
    name: "",
    licencePlateNumber: "",
    active: true,
    modify: false,
    modifyUserVisible: false,
    selectedId: null,
    email: "",
    lastName: "",
    position: "",
    firstName: "",
    phoneNumber: "",
    modifyGuestVisible: false,
    representedCompany: null,
    postCreatedModify: false,
    selectedVehicleId: null,
    isGuest: false,
  };

  formRef = React.createRef();
  componentDidMount = async () => {
    if (this.hasRight([userRoles.OFFICE_ADMIN])) {
      await this.props.getAllCompanies();
    }
    //await this.props.getAllVehicle();
    await this.getVehicles();
    this.setState({ loaded: true });
  };

  getVehicles = async () => {
    this.setState({ loaded: false });
    await this.props.getAllVehicle();
    if (this.props.vehiclesStatus) {
      this.setState({
        vehicles: this.props.vehicles,
      });
    }
    this.setState({ loaded: true });
  };

  handleChangeTable = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t("search-button-label")}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            {this.props.t("reset-button-label")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const nestedValue = lodash.get(record, dataIndex);
      if (nestedValue !== undefined) {
        return nestedValue
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => {
      return lodash.isEqual(this.state.searchedColumn, dataIndex) ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      );
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  handleChangeLicencePlateNumber = (event) => {
    this.setState({ licencePlateNumber: event.target.value });
  };

  hasRight = (rights, notAllowedSubscriptions) => {
    let allowed = false;
    this.props.user.groups.forEach((group) => {
      if (rights.includes(group.id)) {
        allowed = true;
      }
    });
    if (allowed === true && notAllowedSubscriptions === undefined) {
      return true;
    } else {
      if (allowed === true) {
        if (this.allowedBySubscription(notAllowedSubscriptions)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  allowedBySubscription = (notAllowedSubscriptions) => {
    if (this.props.user.companyId === null) {
      return false;
    } else {
      if (
        notAllowedSubscriptions !== undefined &&
        notAllowedSubscriptions !== null
      ) {
        if (
          notAllowedSubscriptions.includes(
            this.props.user.company.subscriptionId
          )
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  handleCloseDetails = async () => {
    this.setState({ detailsModalVisible: false });
    this.cancelModify();
  };

  handleOpenModifyModal = async (record) => {
    this.setState({
      openModal: true,
      modify: true,
      selectedVehicleId: record.id,
      licencePlateNumber: record.licencePlateNumber,
      licencePlateCountryCode: record.licencePlateCountryCode,
      active: record.active,
    });
    this.formRef.current.setFieldsValue({
      licencePlateNumber: record.licencePlateNumber,
      licencePlateCountryCode: record.licencePlateCountryCode,
      active: record.active,
    });
  };

  handleCloseModifyModal = async () => {
    this.setState({
      openModal: false,
      selectedVehicleId: null,
      licencePlateNumber: null,
      licencePlateCountryCode: null,
      active: false,
      modify: false,
    });
    this.formRef.current.resetFields();
  };

  deleteVehicle = async (record) => {
    await this.props.deleteVehicle(record.id);
    await this.getVehicles();
  };

  modifyVehicle = async () => {
    const toSend = {
      licencePlateNumber: this.state.licencePlateNumber,
      active: this.state.active,
    };

    await this.props.updateVehicle(this.state.selectedVehicleId, toSend);
    await this.getVehicles();

    this.handleCloseModifyModal();
  };

  setModify = async (vehicleId) => {
    this.setState({
      modify: true,
      selectedVehicleId: vehicleId,
    });
  };

  cancelModify = async () => {
    this.setState({
      modify: false,
      name: null,
      active: true,
      licencePlateNumber: "",
      selectedVehicleId: null,
    });
  };

  handleActivateVehicles = async () => {
    if (this.state.active === false) {
      this.setState({
        active: true,
      });
    } else {
      this.setState({
        active: false,
      });
    }
  };

  handleClickModify = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      await this.handleSubmit();
    }
  };

  handleSubmit = async () => {
    const user = {
      id: this.state.selectedId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      company: this.state.representedCompany,
      phoneNumber: this.state.phoneNumber,
    };
    if (this.state.modifyGuestVisible) {
      this.state.postCreatedModify
        ? await this.props.modifyPostCreatedGuest(user)
        : await this.props.modifyGuest(user);
    } else {
      await this.props.modifyUser(user);
    }
    if (this.props.saveStatus || this.props.guestSaveStatus) {
      message.success(this.props.t("save-success"), 5);
      await this.getVehicles();
      this.closeModifyUser();
    } else {
      message.error(this.props.t("save-fail"), 5);
    }
  };

  render() {
    const columns = [
      /*  {
        title: this.props.t("vehicles-id-label"),
        dataIndex: "id",
        key: "id",
        align: "center",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['ascend', 'descend'],
        //filteredValue: this.state.filteredInfo.id || null,
        ...this.getColumnSearchProps(
          "id",
          this.props.t("vehicles-id-label")
        ),
      },*/
      {
        title: this.props.t("licensePlateNumber"),
        dataIndex: "licencePlateNumber",
        key: "licencePlateNumber",
        align: "center",
        sorter: (a, b) =>
          a.licencePlateNumber.localeCompare(b.licencePlateNumber),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          "licencePlateNumber",
          this.props.t("licensePlateNumber")
        ),
      },
      {
        title: this.props.t("vehicles-person-name-label"),
        dataIndex: ["personObject", "name"],
        key: "name",
        align: "center",
        sorter: (a, b) =>
          a.personObject.name.localeCompare(b.personObject.name),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          ["personObject", "name"],
          this.props.t("vehicles-person-name-label")
        ),
      },
      {
        title: this.props.t("vehicles-person-email-label"),
        dataIndex: ["personObject", "email"],
        key: "email",
        align: "center",
        sorter: (a, b) =>
          a.personObject.email.localeCompare(b.personObject.email),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          ["personObject", "email"],
          this.props.t("vehicles-person-email-label")
        ),
      },
      {
        title: this.props.t("vehicles-person-company-name-label"),
        dataIndex: ["personObject", "companyName"],
        key: "companyName",
        align: "center",
        sorter: (a, b) =>
          a.personObject.companyName.localeCompare(b.personObject.companyName),
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps(
          ["personObject", "companyName"],
          this.props.t("vehicles-person-company-name-label")
        ),
      },
      /*   {
        title: this.props.t("vehicles-person-office-name-label"),
        dataIndex: ['personObject', 'officeName'],
        key: "officeName",
        align: "center",
        sorter: (a, b) => a.personObject.officeName.localeCompare(b.personObject.officeName),
        sortDirections: ['ascend', 'descend'],
        ...this.getColumnSearchProps(
          ['personObject', 'officeName'],
          this.props.t("vehicles-person-office-name-label")
        ),
      },*/
      {
        title: this.props.t(""),
        key: "isGuest",
        align: "center",
        render: (text, record) => {
          return record.personObject.isGuest ? (
            <h1 style={{ fontSize: "1.5rem" }}>
              <strong>G</strong>
            </h1>
          ) : (
            <h1 style={{ fontSize: "1.5rem" }}>
              <strong>U</strong>
            </h1>
          );
        },
      },
    ];

    if (
      this.hasRight([
        userRoles.SYSTEM_ADMIN,
        userRoles.OFFICE_ADMIN,
        userRoles.COMPANY_ADMIN,
      ])
    ) {
      columns.push(
        {
          title: this.props.t("modify-button-label"),
          key: "modify",
          align: "center",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Button onClick={() => this.handleOpenModifyModal(record)}>
                  <EditFilled />
                </Button>
              </Space>
            );
          },
        },
        {
          title: this.props.t("delete-button-label"),
          key: "delete",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Popconfirm
                  title={this.props.t("vehicle-delete-popup-message")}
                  okText={this.props.t("yes-button-label")}
                  cancelText={this.props.t("no-button-label")}
                  onConfirm={() => this.deleteVehicle(record)}
                >
                  <Button danger type="primary">
                    <DeleteFilled />
                  </Button>
                </Popconfirm>
              </Space>
            );
          },
        }
      );
    }

    return (
      <div>
        <Row>
          <Col span={24}>
            <h1 style={{ fontSize: "2rem", color: "#00A49A" }}>
              <strong>{this.props.t("menu-parking-button-label")}</strong>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={this.state.vehicles}
              locale={{ emptyText: this.props.t("empty-text") }}
              loading={!this.props.vehiclesStatus && !this.state.loaded}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={this.handleChangeTable}
            />
          </Col>
        </Row>

        <Modal
          visible={this.state.openModal}
          onCancel={this.handleCloseModifyModal}
          title={this.props.t("vehicles-modify-vehicle-label")}
          maskClosable={false}
          forceRender
          centered
          footer={[
            <Space>
              <Button key="cancel" onClick={this.handleCloseModifyModal}>
                {this.props.t("cancel-button-label")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.modifyVehicle}
              >
                {this.state.modify
                  ? this.props.t("modify-button-label")
                  : this.props.t("entrypoints-create-title")}
              </Button>
            </Space>,
          ]}
        >
          <Form
            onFinish={this.modifyVehicle}
            ref={this.formRef}
            {...formItemLayout}
            name="design"
            scrollToFirstError
          >
            <Form.Item
              name="licencePlateNumber"
              label={this.props.t("licensePlateNumber")}
              rules={[
                {
                  required: true,
                  message: this.props.t("licensePlateNumber"),
                },
              ]}
            >
              <Input name="licencePlateNumber" onChange={this.handleChange} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  users: state.usersReducer.users,
  usersStatus: state.usersReducer.status,
  saveStatus: state.usersReducer.saveStatus,
  guestSaveStatus: state.receptionReducer.guestSaveStatus,
  vehicle: state.parkingReducer.vehicle,
  vehicles: state.parkingReducer.vehicles,
  vehiclesStatus: state.parkingReducer.vehiclesStatus,
  companies: state.companyReducer.companies,
});
const Vehicles = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedVehicles)
);
export default Vehicles;
