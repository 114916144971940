import React, { Component } from "react";
import { Form, Input, Modal, Button, Select, Checkbox } from "antd";
import { connect } from "react-redux";
import { SaveFilled } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./createOrUpdateEntryPointModal.scss";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class ConnectCreateOrUpdateEntryPointModal extends Component {
  formRef = React.createRef();

  state = {
    modalVisible: this.props.modalVisible || false,
    modalMode: "create",
    selectedEntryPoint: null,
    name: null,
    addressId: null,
    deviceId: null,
    controllerAddress: null,
    openTime: null,
    actuatorsState: null,
    desiredState: null,
    rfidString: null,
    isExit: false,
  };

  componentDidMount = async () => {
    this.setState({
      modalVisible: this.props.modalVisible,
      modalMode: this.props.modalMode,
    });
  };

  componentDidUpdate = () => {
    if (this.props.modalVisible !== this.state.modalVisible) {
      this.setState({
        modalVisible: this.props.modalVisible,
        modalMode: this.props.modalMode,
      });

      if (this.props.selectedEntryPoint) {
        const selectedEntryPoint = this.props.selectedEntryPoint;
        this.setState({
          selectedEntryPoint: selectedEntryPoint,
          name: selectedEntryPoint.name,
          addressId: selectedEntryPoint.addressId,
          deviceId: selectedEntryPoint.deviceId,
          controllerAddress: selectedEntryPoint.controllerAddress,
          openTime: selectedEntryPoint.openTime,
          actuatorsState: selectedEntryPoint.actuatorsState,
          desiredState: selectedEntryPoint.desiredState,
          rfidString: selectedEntryPoint.rfidString,
          isExit: selectedEntryPoint.isExit,
        });

        this.formRef.current.setFieldsValue({
          name: selectedEntryPoint.name,
          addressId: selectedEntryPoint.addressId,
          deviceId: selectedEntryPoint.deviceId,
          controllerAddress: selectedEntryPoint.controllerAddress,
          openTime: selectedEntryPoint.openTime,
          actuatorsState: selectedEntryPoint.actuatorsState,
          desiredState: selectedEntryPoint.desiredState,
          rfidString: selectedEntryPoint.rfidString,
          isExit: selectedEntryPoint.isExit,
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      name: "",
      addressId: "",
      deviceId: "",
      controllerAddress: "",
      rfidString: "",
      openTime: null,
      actuatorsState: null,
      desiredState: null,
      isExit: false,
      epId: null,
    });
    this.formRef.current.resetFields();
    this.props.handleCancelModal();
  };

  handleFormFinish = async () => {
    const saveData = {
      id: this.state.selectedEntryPoint?.id || null,
      companyId: this.props.user?.companyId ? this.props.user.companyId : null,
      officeId: this.props.user?.officeId ? this.props.user.officeId : null,
      name: this.state.name,
      addressId: this.state.addressId,
      deviceId: this.state.deviceId,
      controllerAddress: this.state.controllerAddress,
      rfidString: this.state.rfidString,
      openTime: parseInt(this.state.openTime),
      actuatorsState: parseInt(this.state.actuatorsState),
      desiredState: parseInt(this.state.desiredState),
      isExit: this.state.isExit,
      epId: this.state.epId,
    };

    if (this.state.modalMode === "create") {
      await this.props.createEntryPoint(saveData);
    } else {
      await this.props.updateEntryPoint(saveData);
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeAddress = (event) => {
    this.setState({ addressId: event });
  };

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  handleChangeCheckbox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleOpenModal = async () => {
    this.setState({
      openSearchModal: true,
    });
  };

  render() {
    return (
      <div className="entrypoints">
        <Modal
          visible={this.state.modalVisible}
          title={
            this.state.modalMode === "create"
              ? this.props.t("entrypoints-create-title")
              : this.props.t("entrypoints-update-title")
          }
          onCancel={this.handleCancel}
          footer={null}
          forceRender
        >
          <Form
            className="modal-body"
            name="modifier"
            onFinish={this.handleFormFinish}
            ref={this.formRef}
            {...formItemLayout}
            scrollToFirstError
          >
            <Form.Item
              label={this.props.t("entrypoints-modal-name-label")}
              name="name"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
              hasFeedback
            >
              <Input
                value={this.state.name}
                name="name"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("reception-address-label")}
              name="addressId"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Select
                name="addressId"
                onChange={(event) => this.changeAddress(event)}
                value={this.state.addressId}
              >
                {this.props.addresses.map((address) => {
                  return (
                    <Select.Option key={address.id} value={address.id}>
                      {address.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.props.t("device-label")}
              name="deviceId"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input
                value={this.state.deviceId}
                name="deviceId"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("controller-address-label")}
              name="controllerAddress"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
                {
                  pattern: /^\d+/g,
                  message: this.props.t("must-be-number-label"),
                },
              ]}
            >
              <Input
                value={this.state.controllerAddress}
                name="controllerAddress"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("open-time-label")}
              name="openTime"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
                {
                  pattern: /^\d+/g,
                  message: this.props.t("must-be-number-label"),
                },
              ]}
            >
              <Input
                value={this.state.openTime}
                name="openTime"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("actuator-state-label")}
              name="actuatorsState"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
                {
                  pattern: /^[0-4]*$/,
                  message: this.props.t("actuators-state-modal-wrong-format"),
                },
              ]}
            >
              <Input
                value={this.state.actuatorsState}
                name="actuatorsState"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("desired-state-label")}
              name="desiredState"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
                {
                  pattern: /^\d+/g,
                  message: this.props.t("must-be-number-label"),
                },
              ]}
            >
              <Input
                value={this.state.desiredState}
                name="desiredState"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("rfid-string-label")}
              name="rfidString"
              rules={[
                {
                  required: true,
                  message: this.props.t("entrypoints-modal-must-fill"),
                },
              ]}
            >
              <Input
                value={this.state.rfidString}
                name="rfidString"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              label={this.props.t("entrypoints-is-exit")}
              name="isExit"
              initialValue={this.state.isExit}
            >
              <Checkbox
                id="isExit"
                name="isExit"
                onChange={this.handleChangeCheckbox}
                checked={this.state.isExit}
              />
            </Form.Item>
            <Form.Item
              {...tailFormItemLayout}
              style={{
                textAlign: "right",
                width: "100%",
              }}
            >
              <Button
                key="cancel"
                onClick={this.handleCancel}
                style={{ marginRight: "1em" }}
              >
                {this.props.t("cancel-button-label")}
              </Button>
              <Button type="primary" htmlType="submit">
                <SaveFilled />
                {this.state.modalMode === "create"
                  ? this.props.t("entrypoints-create-title")
                  : this.props.t("entrypoints-update-title")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const CreateOrUpdateEntryPointModal = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectCreateOrUpdateEntryPointModal)
);
export default CreateOrUpdateEntryPointModal;
